import Helmet from "react-helmet"
import React from "react"
import HomepageLayout from "src/layouts/homepage"
import SimplyTemp from "src/components/modules/simplyTemp"
import {isIeEdge,isChrome,isFirefox} from "@tightrope/lpscripts/browserdetect"

const simplyData = import("./data/data.json");

export default function simplyTest() {

  return(
    <HomepageLayout>
      <Helmet>
<style type="text/css">{`

#ctadisclosuresearch-module--ctadisclaimersearch  {
  width: 585px;
  padding: 0;
  margin: 0 auto;
}

#ctadisclosure-module--ctadisclaimer {
  padding: 0px 7%;
  padding-bottom: 0;
}

`}
   </style>

      <title>Freshy Search for Safari- freshysearch.com</title></Helmet>
      <SimplyTemp data={simplyData}></SimplyTemp>
    </HomepageLayout>
  )
}
